import uuid4 from 'uuid4';

// old version
export async function fetchVodFullInfo2(vodId) {
  const settingsStore = useSettingsStore();
  const { vodUrl } = storeToRefs(settingsStore);

  const { video } = await useApi('/video/' + vodId, { query: { requestName: 'fetchFullInfo' } }, vodUrl);
  return video;
}

// new version
export async function fetchVodFullInfo(vodId) {
  const { vodUrl } = storeToRefs(useSettingsStore());
  const id = uuid4();

  const { data: vodFull, fetch: fetchVodFull } = useApiData(
    `video/${vodId}`,
    {
      id,
      query: { requestName: 'fetchFullInfo' },
      watch: vodUrl,
      transform: d => d.video,
      before: () => {
        if (!vodId || !vodUrl.value) return false;
        return vodId;
      },
    },
    vodUrl,
  );
  await fetchVodFull();
  return vodFull.value;
}

export async function fetchVodUrl(vodId) {
  const settingsStore = useSettingsStore();
  const { vodUrl } = storeToRefs(settingsStore);
  const { url } = await useApi('/video/' + vodId + '/stream', { query: {} }, vodUrl);
  return url;
}

export function fetchGenreListById(genreId, page = 0, limit = 20) {
  const settingsStore = useSettingsStore();
  const { vodUrl } = storeToRefs(settingsStore);

  return useApi('genre/' + genreId + '/videos', { query: { page: page, limit: limit } }, vodUrl);
}
